import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// import MobileStepper from '@material-ui/core/MobileStepper';
// import MobileStepper from '@material-ui/core/MobileStepper';
// import Paper from '@material-ui/core/Paper';
// import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
// import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
// import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import Grid from '@material-ui/core/Grid';
import Container from "@material-ui/core/Container";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const bannerSlides = [
  {
    copyImagePath:
      '/bnr_txt_puckitup@2x.png',
    bgImagePath:
      '/bnr_bg_puckitup@2x.jpg',
    buttonText: 'Watch now',
    buttonLink: 'https://get.betterthan.vegas/btv-showdown/',
    openLinkInNewTab: true,
  },
  {
    copyImagePath:
      '/btv-home-bnr-1000.png',
    bgImagePath:
      '/bnr_bg_default@2x.webp',
    buttonText: 'CHECK THE ACTION!',
    buttonLink: 'https://get.betterthan.vegas/btv-showdown/',
    openLinkInNewTab: true,
  }
];

const useStyles = makeStyles((theme) => ({
  hero: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: 240,
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      minHeight: 300,
    },
    [theme.breakpoints.up('md')]: {
      minHeight: 265,
    },
    [theme.breakpoints.up('lg')]: {
      backgroundPositionX: '45%',
    },
    [theme.breakpoints.up('xl')]: {
      minHeight: 350,
    }
  },
  heroImage: {
    maxWidth: "100%",
    paddingRight: "2%",
    position: "relative",
    top: "0",
    [theme.breakpoints.up('sm')]: {
      paddingRight: "7%",
      top: "0",
    },
    [theme.breakpoints.up('md')]: {
      paddingRight: 0,
    },
    [theme.breakpoints.up('lg')]: {
      top: "0",
    },
    [theme.breakpoints.up('xl')]: {
      top: "0",
    }
  },
  heroCaption: {
    color: '#fff',
    fontStyle: 'italic',
  },
  heroButton: {
    backgroundColor: "#E60019",
    color: "#FFF",
    fontWeight: 600,
    fontSize: 21,
    lineHeight: "28px",
    paddingTop: 12,
    paddingBottom: 12,
    marginTop: -16,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    }
  },
  heroLeft: {
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
      "& img, & p": {
        marginLeft: 20
      }
    },
    [theme.breakpoints.up('md')]: {
      "& img, & p": {
      }
    }
  },
  heroRight: {
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      "& a": {
        marginRight: 20
      }
    },
    [theme.breakpoints.up('md')]: {
      "& a": {
        marginRight: 40
      }
    }
  },
}));

function BannerSlider() {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = bannerSlides.length;

  // const handleNext = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  // };

  // const handleBack = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  // };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {bannerSlides.map((slide, index) => (
          <div key={slide.copyImagePath}>
            {Math.abs(activeStep - index) <= 2 ? (

              <div className={classes.hero}>
                <div className="heroBgImage" style={{background: `url(${slide.bgImagePath})`, backgroundPositionX: '35%', backgroundSize: 'cover', position: 'absolute', top: 0, bottom: 0, right: 0, left: 0}}></div>

                <Container maxWidth="xl" style={{height: '100%', position: 'absolute', top: 0, display: 'flex', overflow: 'hidden', alignItems: 'center'}}>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} sm={12} md={7} className={classes.heroLeft}>
                      <img alt="Better than vegas" src={slide.copyImagePath} className={classes.heroImage} />
                      {/* <Typography className={classes.heroCaption}>It's the season of giving and the capping community is setting the tone the only way they know how -making picks!</Typography> */}
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} className={classes.heroRight}>
                      {slide.buttonLink && slide.buttonText && (
                        <Button variant="contained" size="large" color="secondary" className={classes.heroButton} href={slide.buttonLink} target={slide.openLinkInNewTab ? '_blank' : '_self'}>
                          {slide.buttonText}
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Container>
              </div>

            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>


    {/* Show when multiple banners are present 👇 */}

      {/* <MobileStepper
        steps={maxSteps}
        position="static"
        variant="dots"
        activeStep={activeStep}
        nextButton={
          <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
            Next
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            Back
          </Button>
        }
      /> */}
    </>
  );
}

export default BannerSlider;
