import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Layout from "../src/Layout";
import Container from "@material-ui/core/Container";
// import Typography from "@material-ui/core/Typography";
// import VideoFeed from "../src/VideoFeed";
// import GameList from "../src/GameList";
// import TagList from "../src/TagList";
import AllVideosFeed from "../src/AllVideosFeed";
// import Button from '@material-ui/core/Button';
// import Grid from '@material-ui/core/Grid';
import withAuth from "../src/utils/withAuth";

import BannerSlider from "../src/BannerSlider";

const useStyles = makeStyles((theme) => ({
  tagWrapper: {
    marginTop: 20,
    marginBottom: 20,
    [theme.breakpoints.up("md")]: {
      marginTop: 30,
      marginBottom: 30,
    },
  },
  gameWrapper: {
    backgroundColor: "#eaeaeb",
    paddingTop: 20,
    paddingBottom: 20,
    [theme.breakpoints.up("md")]: {
      paddingTop: 30,
      paddingBottom: 30,
    },
  },
  videoWrapper: {
    marginTop: 20,
    marginBottom: 20,
    [theme.breakpoints.up("md")]: {
      marginTop: 30,
      marginBottom: 30,
    },
  },
  bannerAd: {
    background: "#0d1029",
    [theme.breakpoints.down("sm")]: {
      position: "sticky",
    },
    top: 48,
    // padding: 17,
    zIndex: 10,
  },
  bannerAdImage: {
    // maxWidth: 693,
    width: "100%",
    height: "auto",
  },
  bannerAdLink: {
    display: "flex",
    justifyContent: "center",
  },
}));

const Home = (props) => {
  const classes = useStyles();

  return (
    <Layout current="home">
      {/* <div className={classes.bannerAd}>
        <a href="https://record.revmasters.com/_xUOfMR1Jg036lJYWmxbtZGNd7ZgqdRLk/1/%5C" target="_blank" className={classes.bannerAdLink}>
          <img src="/btv-aff-banner-top.gif" alt="NitroBetting banner" className={classes.bannerAdImage} />
        </a>
      </div> */}

      {/* { true && (
        <BannerSlider />
      )} */}

      {/* <Container maxWidth="lg" className={classes.tagWrapper}>
        <TagList />
      </Container> */}

      {/* <div className={classes.gameWrapper}>
        <Container maxWidth="lg">
          <GameList title="Upcoming Events" />
        </Container>
      </div> */}

      <Container maxWidth="lg" className={classes.videoWrapper}>
        <AllVideosFeed limit={12} />
      </Container>
    </Layout>
  );
};

export default withAuth(Home);
