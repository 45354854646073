import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { getChunk } from "./utils/firebase";
import PropTypes from "prop-types";
import Video from "./Video";
import Pagination from "@material-ui/lab/Pagination";

const useStyles = makeStyles((theme) => ({
  feedRoot: {
    flexGrow: 1,
    marginBottom: 40,
  },
  cardRoot: {
    flexGrow: 1,
  },
  cardContent: {
    padding: 8,
    paddingBottom: 0,
  },
  feedTitle: {
    marginBottom: 16,
  },
  title: {
    fontSize: 17,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  author: {
    marginTop: 6,
    color: "#676767",
    fontSize: 15,
  },
  avatar: {
    width: 18,
    height: 18,
  },
  media: {
    height: 160,
  },
  cardFooter: {
    display: "flex",
    justifyContent: "space-between",
  },
  emptyFeedText: {
    marginLeft: 12,
    fontSize: 16,
  },
}));

function AllVideosFeed({ groupByAuthor }) {
  const classes = useStyles();
  const [allVideos, setAllVideos] = useState([]);
  const [paginatedResults, setPaginatedResults] = useState([]);

  const limit = 12;
  const pages = 10;
  const chunk = limit * pages;

  const [currentPage, setCurrentPage] = useState(1);
  const [paginationCount, setPaginationCount] = useState(0);
  const [lastOfChunk, setLastOfChunk] = useState(null);
  const [loading, setLoading] = useState(false);
  const [endReached, setEndReached] = useState(false);

  useEffect(() => {
    // once last chunk is reached, fetch next chunk
    if (!endReached && allVideos.length <= currentPage * limit) {
      let fetchVideos = async () => {
        setLoading(true);
        let result = await getChunk(chunk, lastOfChunk, {
          type: "all",
          filter: "none",
        });
        if (result?.chunkedResults.length < chunk) {
          setEndReached(true);
        }
        setLastOfChunk(result.lastResultRef);
        setAllVideos([...allVideos, ...result.chunkedResults]);
        setPaginationCount(
          paginationCount + Math.floor(result.chunkedResults.length / limit)
        );
        setLoading(false);
      };
      fetchVideos();
    }
    paginateResults(allVideos);
  }, [currentPage, allVideos]);

  // slice results into sets of "limit"
  const paginateResults = (arr) => {
    const begin = (currentPage - 1) * limit;
    const end = begin + limit;

    let list = arr.slice(begin, end);
    setPaginatedResults(list);
  };

  // while traversing up through the pages, only ever increment pagination count by one page at a time
  // this helps to keep firestore queries to a minimum
  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  // last page, this is needed to avoid a blank page at end
  if (currentPage > 1 && paginatedResults.length === 0) {
    return (
      <div className={classes.allFeedsContainer}>
        <div className={classes.feedRoot}>
          <Typography variant="h6" className={classes.feedTitle}>
            Recent Videos
          </Typography>
          <Typography variant="h6" className={classes.feedTitle}>
            You've reached the end!
          </Typography>
          <br />
          {paginationCount > 0 && (
            <Pagination
              count={paginationCount}
              page={currentPage}
              onChange={handleChange}
            />
          )}
        </div>
      </div>
    );
  }

  // render author grouped uploads
  if (groupByAuthor) {
    return (
      <div className={classes.allFeedsContainer}>
        {paginatedResults &&
          paginatedResults.map((userUploads) => {
            const author = userUploads[0];
            const posts = userUploads[1];
            return (
              <div className={classes.feedRoot} key={`${author}s-video-feed`}>
                <Typography variant="h5" className={classes.feedTitle}>
                  {author && `Uploads from ${author}`}
                </Typography>
                {loading && (
                  <Typography variant="h6" className={classes.feedTitle}>
                    Loading Feed...
                  </Typography>
                )}
                <Grid container spacing={3}>
                  {posts &&
                    posts.map((post, postIndex) => {
                      return (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={3}
                          key={`${author}-upload-${postIndex}`}
                        >
                          <Video video={post} />
                        </Grid>
                      );
                    })}
                </Grid>
              </div>
            );
          })}
      </div>
    );
  }

  // default case, render all normal uploads
  return (
    <div className={classes.allFeedsContainer}>
      <div className={classes.feedRoot}>
        <Typography variant="h6" className={classes.feedTitle}>
          Recent Videos
        </Typography>
        {loading && (
          <Typography variant="h6" className={classes.feedTitle}>
            Loading Feed...
          </Typography>
        )}
        <Grid container spacing={3}>
          {paginatedResults?.map((post) => {
            return (
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                key={`${post.meta.authorName}-upload-${post.meta.id}`}
              >
                <Video flex video={post} />
              </Grid>
            );
          })}
        </Grid>
        <br />
        {paginationCount > 0 && (
          <Pagination
            count={paginationCount}
            page={currentPage}
            onChange={handleChange}
          />
        )}
      </div>
    </div>
  );
}

AllVideosFeed.propTypes = {
  groupByAuthor: PropTypes.bool.isRequired,
};

AllVideosFeed.defaultProps = {
  groupByAuthor: false,
};

export default AllVideosFeed;
