import react, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";

import LikeIcon from "@material-ui/icons/ThumbUp";
import CommentIcon from "@material-ui/icons/Chat";

import { db, fv } from "./utils/firebase";
import withAuth from "./utils/withAuth";

import moment from "moment";
import Link from "./Link";

import router from "next/router";

const useStyles = makeStyles((theme) => ({
  ellipsis: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  cardRoot: {
    width: 290,
  },
  cardRootFlex: {
    flexGrow: 1,
  },
  cardContent: {
    padding: 16,
    paddingBottom: 0,
  },
  cardAction: {
    padding: 4,
    minWidth: "auto",
    fontSize: "inherit",
  },
  title: {
    fontSize: 17,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    marginBottom: 4,
    "& a:hover": {
      textDecoration: "none",
    },
  },
  author: {
    marginTop: -40,
    marginBottom: 10,
    fontSize: 15,
    color: "#000",
    fontWeight: 500,
    display: "block",
  },
  authorAvatar: {
    width: 60,
    height: 60,
    borderRadius: 30,
    display: "inline-block",
    marginBottom: -8,
  },
  authorName: {
    display: "inline-block",
    marginLeft: 8,
  },
  media: {
    height: 160,
  },
  cardFooter: {
    display: "flex",
    justifyContent: "space-between",
    padding: 16,
    paddingTop: 0,
  },
}));

const Video = (props) => {
  const { video, flex } = props;

  const classes = useStyles();

  const [likes, setLikes] = useState(video.meta.likes || 0);

  return (
    <Card
      className={flex ? classes.cardRootFlex : classes.cardRoot}
      variant="outlined"
    >
      <CardMedia
        component="a"
        href={`/video/${video.meta.id}`}
        className={classes.media}
        image={
          video.meta && video.meta.thumbnail && video.meta.thumbnail !== ""
            ? video.meta.thumbnail
            : `https://image.mux.com/${video.video_asset.playback_ids[0].id}/thumbnail.jpg`
        }
        title={video.meta.title ? video.meta.title : "No Post Title"}
      />
      <CardContent className={classes.cardContent}>
        <Link
          href={`/profile/${video.meta.authorName}`}
          className={classes.author}
        >
          <img
            src={`/api/avatar/${video.meta.authorName}`}
            className={classes.authorAvatar}
          />
          <span className={classes.authorName}>{video.meta.authorName}</span>
        </Link>
        <Typography variant="h6" component="h2" className={classes.title}>
          <Link href={`/video/${video.meta.id}`}>
            {video.meta.title ? video.meta.title : "No Post Title"}
          </Link>
        </Typography>
      </CardContent>
      <CardActions disableSpacing className={classes.cardFooter}>
        <Typography variant="caption" color="textSecondary" style={{ flex: 1 }}>
          {`${video.meta.views || 0} ${
            video.meta.views === 1 ? `view` : `views`
          } - ${moment(video.meta.created.toDate()).fromNow()}`}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          <Button
            href={`/video/${video.meta.id}#comment-feed-input`}
            className={classes.cardAction}
            style={{ marginRight: 8 }}
            color="inherit"
            size="small"
            startIcon={<CommentIcon />}
          >
            {video.meta.comments || 0}
          </Button>
          <Button
            className={classes.cardAction}
            color="inherit"
            size="small"
            startIcon={<LikeIcon />}
            onClick={async () => {
              const path = `users/${video.meta.author}/uploads/${video.meta.id}`;
              // check if user is authenticated and if they have liked the video
              if (!props.auth.authenticated) {
                router.push({
                  pathname: "/register",
                  query: { like: video.meta.id },
                });
                return;
              }
              // create result object
              const result = await db
                .doc(path)
                .collection("likes")
                .where("user", "==", props.auth.user.email)
                .get();
              // if no result add to firebase collection with user data
              if (result.empty) {
                await db.doc(path).collection("likes").add({
                  user: props.auth.user.email,
                  userName: props.auth.userData.username,
                  created: new Date(),
                });

                await db.doc(path).set(
                  {
                    meta: { likes: (video.meta.likes || 0) + 1 },
                  },
                  { merge: true }
                );

                setLikes(likes + 1);
              }
            }}
          >
            {likes}
          </Button>
        </Typography>
      </CardActions>
    </Card>
  );
};

export default withAuth(Video);
